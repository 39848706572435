/* global melidata */

/**
 * Event Tracker
 *
 * @param {String} path
 * @param {String} data
 * @param {String} clean
 *
 * If you send clean = true the event is going to change to cleanAndSend
 *
 * @example
 * trackEvent({
 *     path: '/search/listings/',
 *     event_data: {},
 *     experiments: {}
 * });
 */
function trackEvent({ path = null, event_data = null, clean = false, type = 'event', experiments } = {}) {
  // If melidata is not defined do nothing for now
  if (typeof melidata !== 'function') {
    return;
  }

  // If one of this parameters is empty do not track
  if (!path) {
    return;
  }

  const actionType = clean ? 'cleanAndSend' : 'send';

  if (event_data) {
    melidata(actionType, type, {
      path,
      event_data,
      experiments,
    });
  } else {
    melidata(actionType, type, {
      path,
      experiments,
    });
  }
}

const enhancedTrackMelidata = (melidataTracks, eventTrack, selectedItem) => {
  if (eventTrack) {
    eventTrack.event_data.selected_item = selectedItem;

    const trackToAppend = {
      ...melidataTracks[eventTrack.path].event_data,
      ...eventTrack.event_data,
    };

    trackEvent({
      path: eventTrack.path,
      ...melidataTracks[eventTrack.path],
      event_data: trackToAppend,
      clean: true,
    });

    if (melidataTracks[eventTrack.path].append_default_even_data) {
      trackEvent({
        path: '/search',
        ...melidataTracks['/search'],
        event_data: trackToAppend,
      });
    }
  }
};

module.exports = {
  trackEvent,
  enhancedTrackMelidata,
};
